<template>
  <v-row no-gutters align="center" justify="center">
    <v-autocomplete
      outlined
      height="48px"
      color="#E2E2E2"
      style="margin: 0"
      placeholder="Звідки"
      dense
      hide-details
      class="adminSearchField"
      v-model="start_city"
      :items="Object.values(startCities)"
      :item-text="
        (city) =>
          `${city?.translations?.name}, ${city?.country?.translations?.name}`
      "
      :item-value="null"
    />

    <v-autocomplete
      outlined
      height="48px"
      color="#E2E2E2"
      style="margin: 0"
      placeholder="Куди"
      dense
      hide-details
      class="adminSearchField"
      v-model="next_city"
      :items="Object.values(nextCities)"
      :item-text="
        (city) =>
          `${city?.translations?.name}, ${city?.country?.translations?.name}`
      "
      :item-value="(city) => city"
      :error-messages="nextCityError"
    />
    <v-menu offset-y :close-on-content-click="false" nudge-left="30px">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          v-on="on"
          outlined
          height="48px"
          style="margin: 0"
          color="#E2E2E2"
          placeholder="Сьогодні"
          dense
          hide-details
          clearable
          :value="`${new Date(date?.[0]).toLocaleDateString('uk-UA', {
            month: 'short',
            day: '2-digit',
          })}-${
            date?.[1] !== undefined
              ? new Date(date?.[1]).toLocaleDateString('uk-UA', {
                  month: 'short',
                  day: '2-digit',
                })
              : ''
          }`"
          @click:clear="date = ['', '']"
          class="adminSearchField"
        >
          <template v-slot:prepend-inner>
            <img src="@/assets/img/iconsSvg/calendarIcon.svg" />
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        color="#144FA9"
        v-model="date"
        locale="uk"
        no-title
        range
        :min="minDate"
      />
    </v-menu>
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          height="48px"
          v-bind="attrs"
          v-on="on"
          color="#E2E2E2"
          style="margin: 0"
          placeholder="01 пасажир"
          dense
          hide-details
          :value="`${quanityPeople} ${
            quanityPeople > 4 || quanityPeople == 0
              ? 'пасажирів'
              : quanityPeople > 1 && quanityPeople < 5
              ? 'пасажири'
              : 'пасажир'
          }`"
          class="adminSearchField"
        />
      </template>
      <div
        style="
          border-radius: 5px;
          border: 1px solid rgba(181, 181, 181, 0.3);
          background: #fcfcfc;
          padding: 20px;
          width: 230px;
        "
      >
        <v-row no-gutters align="center">
          <v-col cols="6">
            <span class="userType">Пасажирів</span>
          </v-col>
          <v-icon
            color="#1B1B1B"
            size="24px"
            style="margin-left: 8px; cursor: pointer"
            @click="quanityPeople > 1 ? quanityPeople-- : ''"
            >mdi-minus-circle-outline</v-icon
          >
          <p style="margin: 0px 8px">{{ quanityPeople }}</p>
          <v-icon
            color="#1B1B1B"
            size="24px"
            style="cursor: pointer"
            @click="quanityPeople++"
            >mdi-plus-circle-outline</v-icon
          >
        </v-row>
      </div>
    </v-menu>
    <v-btn class="searchBtn" @click="searchTicket">Пошук</v-btn>
  </v-row>
</template>
  
  <script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import adminSearchScheduleService from "@/requests/Admin/adminSearchScheduleService.js";
export default {
  mixins: [validationMixin],
  data: () => ({
    isFromFieldHover: false,
    isToFieldHover: false,
    date: [
      new Date().toISOString().substring(0, 10),
      new Date().toISOString().substring(0, 10),
    ],
    quanityPeople: 1,
    startCities: [],
    nextCities: [],
    start_city: {
      translations: {
        name: "",
      },
    },
    next_city: {
      translations: {
        name: "",
      },
    },
  }),
  validations: {
    start_city: {
      id: { required },
    },
    next_city: {
      id: {
        required,
      },
    },
    date: {
      required,
    },
  },
  props: {
    departure_city: {
      require: false,
    },
    arrival_city: {
      require: false,
    },
  },
  mounted() {
    this.getStartCities();
  },
  methods: {
    searchTicket() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$router.push(
          `/admin/trips?departureCity=${this.start_city.id}&arrivalCity=${
            this.next_city.id
          }&startDate=${new Date(this.date?.[0])
            .toISOString()
            .substring(0, 10)}&endDate=${new Date(this.date?.[1])
            .toISOString()
            .substring(0, 10)}&qty=${this.quanityPeople}`
        );
      }
    },
    async getStartCities() {
      await adminSearchScheduleService.getStartCities().then((res) => {
        if (res.status == "Success") {
          this.startCities = res.data;
          if (this.$route.name == "ticket_search") {
            this.setSearchField();
          }
          if (this.$route.name == "route_detail_page") {
            this.start_city = this.startCities.find(
              (city) => city.id == this.departure_city
            );
            this.next_city = this.startCities.find(
              (city) => city.id == this.arrival_city
            );
          }
          if (Object.values(this.$route.query).length > 0) {
            this.setSearchField();
          }
        }
      });
    },
    async getNextCities() {
      await adminSearchScheduleService
        .getNextCities(this.start_city.id)
        .then((res) => {
          if (res.status == "Success") {
            this.nextCities = res.data;
            if (Object.values(this.$route.query).length > 0) {
              this.setSearchField();
            }
          }
        });
    },
    setSearchField() {
      this.start_city = this.startCities.find(
        (city) => city.id == this.$route.query.departureCity
      );
      this.next_city = this.nextCities.find(
        (city) => city.id == this.$route.query.arrivalCity
      );
      this.date = [this.$route?.query?.startDate, this.$route?.query?.endDate];
      this.quanityPeople = this.$route?.query?.qty;
    },
  },
  computed: {
    minDate(){
      if(this.date.length > 0 && this.date?.[0] !== ''){
        return new Date(this.date?.[0]).toISOString().substring(0, 10);
      }
      return new Date().toISOString().substring(0, 10);
    },
    startCityError() {
      const errors = [];
      let field = this.$v.start_city.id;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    nextCityError() {
      const errors = [];
      let field = this.$v.next_city.id;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
  },
  watch: {
    start_city: {
      deep: true,
      handler() {
        if (
          this.start_city !== null &&
          this.start_city !== undefined &&
          this.start_city !== ""
        ) {
          this.getNextCities();
        }
      },
    },
    "$route.path": {
      deep: true,
      handler() {
        this.setSearchField();
      },
    },
  },
};
</script>
  
  <style scoped>
.adminSearchField {
  height: 48px !important;
  border-radius: 10px !important;
  margin-right: 20px !important;
}
.searchBtn {
  text-transform: none;
  border-radius: 10px;
  background: #144fa9 !important;
  width: 100px !important;
  height: 48px !important;
  color: #fafafa;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.userType {
  color: #1b1b1b;
  font-family: "MacPaw Fixel Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>
  <style>
.adminSearchField.v-text-field input {
  color: #4b5262;
  font-family: "MacPaw Fixel Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>